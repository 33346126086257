import {PagedTradingPartnerList} from '../models/TradingPartner';

export const UPDATE_TRADINGPARTNER_LIST = 'UPDATE_TRADINGPARTNER_LIST';

export interface UpdateTradingPartnerListAction {
  type: typeof UPDATE_TRADINGPARTNER_LIST;
  payload: PagedTradingPartnerList;
}

export type TradingPartnerActionTypes = UpdateTradingPartnerListAction;
