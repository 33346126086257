import {authRole} from 'shared/constants/AppConst';

export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'transactions',
    title: 'Transactions',
    messageId: 'p1.transactions',
    type: 'group',
    icon: 'work',
    children: [
      {
        id: 'transactions-main',
        title: 'Transaction',
        messageId: 'p1.transactions.main',
        type: 'item',
        url: '/transactions/main',
      },
    ],
  },
  {
    id: 'settings',
    title: 'Settings',
    messageId: 'p1.settings',
    type: 'group',
    icon: 'settings',
    children: [
      {
        id: 'settings-compiledScripts',
        title: 'Scripts',
        messageId: 'p1.settings.mappers',
        type: 'item',
        url: '/settings/compiledScripts/main',
        auth: authRole.admin,
      },
      {
        id: 'settings-documents',
        title: 'Documents',
        messageId: 'p1.settings.documents',
        type: 'item',
        url: '/settings/documents/main',
        auth: authRole.admin,
      },
      {
        id: 'settings-processes',
        title: 'Processes',
        messageId: 'p1.settings.processes',
        type: 'item',
        url: '/settings/processes/main',
        auth: authRole.admin,
      },
      {
        id: 'settings-tradingPartners',
        title: 'Trading Partners',
        messageId: 'p1.settings.tradingPartners',
        type: 'item',
        url: '/settings/tradingPartners/main',
        auth: authRole.admin,
      },
      {
        id: 'settings-xferTables',
        title: 'Cross Reference Tables',
        messageId: 'p1.settings.xferTables',
        type: 'item',
        url: '/settings/xferTables/main',
        auth: authRole.admin,
      },
      {
        id: 'settings-endpoints',
        title: 'Endpoints',
        messageId: 'p1.settings.endpoints',
        type: 'item',
        url: '/settings/endpoints/main',
        auth: authRole.admin,
      },
      {
        id: 'settings-notifications',
        title: 'Notifications',
        messageId: 'p1.settings.notifications',
        type: 'item',
        url: '/settings/notifications/main',
        auth: authRole.admin,
      },
      {
        id: 'settings-emailTemplates',
        title: 'Email Templates',
        messageId: 'p1.settings.emailTemplates',
        type: 'item',
        url: '/settings/emailTemplates/main',
        auth: authRole.admin,
      },
    ],
  },
];
export default routesConfig;
