import {
  EmailTemplateActionTypes,
  UPDATE_EMAILTEMPLATE_LIST,
} from '../../types/actions/EmailTemplate.action';
import {PagedEmailTemplateList} from '../../types/models/EmailTemplate';

const INIT_STATE: PagedEmailTemplateList = {
  pageNumber: 0,
  pageSize: 30,
  totalCount: 0,
  items: ([] = []),
};

const EmailTemplateReducer = (
  state = INIT_STATE,
  action: EmailTemplateActionTypes,
) => {
  switch (action.type) {
    case UPDATE_EMAILTEMPLATE_LIST: {
      return {
        ...state,
        items: action.payload.items,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
      };
    }
    default:
      return state;
  }
};
export default EmailTemplateReducer;
