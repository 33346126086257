import React, {useMemo, useState, useEffect} from 'react';
import spacetime from 'spacetime';
import TimezoneSelect, {
  allTimezones,
  useTimezoneSelect,
} from 'react-timezone-select';
import type {
  ITimezone,
  ILabelStyle,
  TimezoneSelectOptions,
  ITimezoneOption,
} from 'react-timezone-select';
import {MenuItem, FormControl, Select, InputLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

export type ISelectStyle = 'react-select' | 'select';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: 25,
    width: 750,
    margin: '0 auto',
  },
  selectWrapper: {
    marginTop: 15,
    width: 750,
    maxWidth: '90vw',
    textAlign: 'left',
  },
  spacer: {
    marginBottom: 20, // Adjust this value to control the spacing
  },
  select: {
    minWidth: 200,
    flex: 1,
    textAlign: 'center',
  },
  formControl: {
    width: '30%',
    textAlign: 'center',
  },
  menuItem: {
    justifyContent: 'center', // Center the text inside each MenuItem
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: 20,
  },
  buttonWrapper: {
    textAlign: 'center',
    justifyContent: 'space-between',
    display: 'grid',
    alignItems: 'center',
    marginTop: 15,
  },
  labelStyleSelect: {
    border: '1px solid #ddd',
    borderRadius: 6,
    padding: '10px 20px',
    marginTop: 20,
    '& label': {
      marginLeft: 50,
    },
  },
  saveButton: {
    backgroundColor: '#5bc6db',
    color: '#ffffff',
    fontSize: '0.9',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: 'auto',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#469fb1',
    },
  },
  code: {
    backgroundColor: '#efefef',
    marginTop: 20,
    padding: 30,
    fontFamily: 'monospace',
    fontWeight: 100,
    fontSize: '1.2rem',
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'normal',
      wordBreak: 'keep-all',
    },
  },
}));

const timezones = {
  ...allTimezones,
  'America/Lima': 'Pittsburgh',
  'Europe/Berlin': 'Frankfurt',
};

const Timezone = () => {
  const classes = useStyles();
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(() => {
    // Check if a timezone is already saved in local storage
    const storedTimezone = localStorage.getItem('userTimezone');
    return storedTimezone ? JSON.parse(storedTimezone) : '';
  });

  const [dateFormat, setDateFormat] = useState(() => {
    return localStorage.getItem('userDateFormat') || 'dd/MM/yyyy';
  });

  const [datetime, setDatetime] = useState(spacetime.now());

  /*useEffect(() => {
    // Save the selected timezone to local storage whenever it changes
    if (selectedTimezone) {
      localStorage.setItem('userTimezone', JSON.stringify(selectedTimezone));
      console.log('Timezone saved to local storage:', selectedTimezone);
      console.log('Offset type:', typeof selectedTimezone.offset);
      console.log('Offset value:', selectedTimezone.offset);
    }
  }, [selectedTimezone]);
  */

  useMemo(() => {
    const tzValue =
      typeof selectedTimezone === 'string'
        ? selectedTimezone
        : selectedTimezone?.value;
    setDatetime(datetime.goto(tzValue));
  }, [selectedTimezone]);

  const handleSave = () => {
    if (selectedTimezone) {
      // Save the selected timezone to local storage
      localStorage.setItem('userTimezone', JSON.stringify(selectedTimezone));
      localStorage.setItem('userDateFormat', dateFormat);
      console.log('Timezone saved to local storage:', selectedTimezone);

      // Refresh the page to reflect changes
      window.location.reload();
    }
  };

  const selectOptions = {
    labelStyle: 'abbrev',
    timezones,
  };

  const handleDateFormatChange = (event) => {
    setDateFormat(event.target.value);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>Time Zone Selector</div>
      <div className={`${classes.selectWrapper}`}>
        <TimezoneSelect
          value={selectedTimezone}
          onChange={setSelectedTimezone}
          {...selectOptions}
        />
      </div>
      <div className={classes.spacer}></div>
      <div>
        <FormControl variant='standard' className={classes.formControl}>
          <InputLabel>Date Format</InputLabel>
          <Select
            value={dateFormat}
            onChange={handleDateFormatChange}
            label='Date Format'
            className={classes.select}>
            <MenuItem value='dd/MM/yyyy' className={classes.menuItem}>
              DD/MM/YYYY
            </MenuItem>
            <MenuItem value='MM/dd/yyyy' className={classes.menuItem}>
              MM/DD/YYYY
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={classes.buttonWrapper}>
        <button className={classes.saveButton} onClick={handleSave}>
          SAVE
        </button>
      </div>
      <div className={classes.code}>
        <div>
          <strong>Current Date / Time:</strong>{' '}
          <pre>
            {' '}
            {/* Changes the date format of the Preview */}
            {datetime.unixFmt(dateFormat.replace('yyyy', 'YY') + ' / HH:mm:ss')}
          </pre>
        </div>
        {selectedTimezone && (
          <div>
            <div>
              <strong>Location:</strong> {selectedTimezone.value}
            </div>
            <div>
              <strong>Time Zone:</strong> {selectedTimezone.label}
            </div>
            <div>
              <strong>Alt. Name:</strong> {selectedTimezone.altName}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

type Props = {
  value: ITimezone;
  selectOptions: TimezoneSelectOptions;
  onChange?: (timezone: ITimezoneOption) => void;
};

function NativeSelectTimezone({selectOptions, value, onChange}: Props) {
  const {options, parseTimezone} = useTimezoneSelect(selectOptions);
  return (
    <select
      value={parseTimezone(value)?.value}
      onChange={(e) => onChange(parseTimezone(e.currentTarget.value))}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default Timezone;
