import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import AppLayout from '@crema/core/AppLayout';
import AuthRoutes from '@crema/utility/AuthRoutes';
import LocaleProvider from '@crema/utility/LocaleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import ContextProvider from '@crema/utility/ContextProvider';

import configureStore, {history} from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';
import { PublicClientApplication, EventType } from "@azure/msal-browser"
import { msalConfig, b2cPolicies} from "./authConfig";
import { MsalProvider } from '@azure/msal-react';

import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

const msalInstance = new PublicClientApplication(msalConfig)
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  console.log(event)
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
      
      if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
        let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
        };
        msalInstance.loginRedirect(signUpSignInFlowRequest);
    }
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
        const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
        };
        msalInstance.loginRedirect(resetPasswordRequest);
    }
}
});

export function compareIssuingPolicy(idTokenClaims, policyToCompare) {
  let tfpMatches = idTokenClaims.hasOwnProperty('tfp') && idTokenClaims['tfp'].toLowerCase() === policyToCompare.toLowerCase();
  let acrMatches = idTokenClaims.hasOwnProperty('acr') && idTokenClaims['acr'].toLowerCase() === policyToCompare.toLowerCase();
  return tfpMatches || acrMatches;
}

msalInstance.handleRedirectPromise().then(authResult=>{
  // Check if user signed in 
  const account = msalInstance.getActiveAccount();
  if(!account){    
    console.log("Redirect to login")
    // redirect anonymous user to login page 
    msalInstance.loginRedirect();
  }
  else{
    console.log(authResult)
  }
}).catch(err=>{
  // TODO: Handle errors
  console.log(err);
});

const store = configureStore();
const persistor = persistStore(store);
const App = () => (
  <MsalProvider instance={msalInstance}>
  <ContextProvider>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CremaThemeProvider>
        <CremaStyleProvider>
          <LocaleProvider>
            <ConnectedRouter history={history}>
              <AuthRoutes>
                <CssBaseline />
                <AppLayout />
              </AuthRoutes>
            </ConnectedRouter>
          </LocaleProvider>
        </CremaStyleProvider>
      </CremaThemeProvider>
    </PersistGate>
    </Provider>
  </ContextProvider>
  </MsalProvider>
);

export default App;
