import {PagedEmailTemplateList} from '../models/EmailTemplate';

export const UPDATE_EMAILTEMPLATE_LIST = 'UPDATE_EMAILTEMPLATE_LIST';

export interface UpdateEmailTemplateListAction {
  type: typeof UPDATE_EMAILTEMPLATE_LIST;
  payload: PagedEmailTemplateList;
}

export type EmailTemplateActionTypes = UpdateEmailTemplateListAction;
