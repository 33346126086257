import React from 'react';
import { authRole } from 'shared/constants/AppConst';

export const transactionPagesConfig = [
  {
    auth: [...authRole.admin,...authRole.manager,...authRole.user],
    routes: [
      {
        path: '/transactions/main',
        component: React.lazy(() => import('./main')),
      },
    ],
  },
  {
    auth: [...authRole.admin,...authRole.manager,...authRole.user],
    routes: [
      {
        path: '/transactions/:id',
        component: React.lazy(() => import('./detail')),
      },
    ],
  }
];
