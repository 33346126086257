import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
//import {defaultUser} from '../../shared/constants/AppConst';
import {AuthUser} from '../../types/models/AuthUser';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../types/actions/Auth.actions';
import {authRole} from 'shared/constants/AppConst';

export const onJwtUserSignUp = (body: {
  email: string;
  password: string;
  name: string;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.post('users', body);
      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      await loadJWTUser(dispatch, res.data.result);
    } catch (err) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const onJwtSignIn = (body: {email: string; password: string}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.post('auth', body);
      dispatch(fetchSuccess());
      localStorage.setItem('token', res.data.result.token);
      dispatch(setJWTToken(res.data.result.token));
      await loadJWTUser(dispatch, res.data.result);
    } catch (err) {
      console.log('error!!!!', err.response);
      dispatch(fetchError(err.response));
    }
  };
};

export const onJwtSignInWithADB2C = (res: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      localStorage.setItem('token', res.accessToken);
      console.log(res);
      dispatch(setJWTToken(res.accessToken));
      await loadJWTUser(dispatch, {
        displayName: res.account.username,
        email: res.account.username,
        role: res.account.idTokenClaims.extension_UserRole ?? authRole.admin,
        token: res.accessToken,
        uid: res.account.username,
      });
    } catch (err) {
      console.log('error!!!!', err.response);
      dispatch(fetchError(err.response));
    }
  };
};

export const loadJWTUser = async (
  dispatch: Dispatch<AppActions>,
  user: any,
) => {
  //dispatch(fetchStart());
  try {
    //const res = await jwtAxios.get('/auth');
    //dispatch(fetchSuccess());
    //console.log('res.data', res.data);
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: getUserObject(user),
    });
  } catch (err) {
    console.log('error!!!!', err.response.error);
    dispatch(fetchError(err.response.error));
  }
};

export const setJWTToken = (token: string | null): AppActions => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

const getUserObject = (authUser: any): AuthUser => {
  return {
    authType: AuthType.JWT_AUTH,
    displayName: authUser.email,
    email: authUser.email,
    role: authUser.role,
    token: authUser.token,
    refreshToken: authUser.refreshToken,
    uid: authUser.email,
    //photoURL: authUser.avatar,
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchSuccess());
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch(fetchSuccess());
      localStorage.removeItem('token');
    }, 500);
  };
};
