import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api'; // import a JWT-secured Axios instance
import {fetchError, fetchStart, fetchSuccess} from './Common'; // import action creators for fetch status
import {AppActions} from '../../types'; // import the type for Redux actions
import {Dispatch} from 'redux'; // import the Dispatch type from Redux
import {UPDATE_NOTIFICATIONS_LIST} from '../../types/actions/Notifications.action'; // import an action type constant

export const onRequestNotifications = (body: {
  // define the function with a body object parameter
  pageNumber: number;
  pageSize: number;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    // return an async function that takes a Dispatch function
    dispatch(fetchStart()); // dispatch a fetch start action

    try {
      const res = await jwtAxios.get(
        'Notifications/Basic?pageNumber=' +
          body.pageNumber +
          '&pageSize=' +
          body.pageSize,
      ); // make an API request to fetch notifications
      dispatch(fetchSuccess()); // dispatch a fetch success action
      const items = res.data.result; // extract the notification items from the response data
      const pageIndex = 0; // set the current page index to 0
      const totalPages = 1; // set the total number of pages to 1
      const pageNumber = 1; // set the current page number to 1
      const pageSize = body.pageSize; // set the page size from the input parameter
      const totalCount = items.length; // set the total number of notifications to the number of items in the response

      const transformedData = {
        // create a new object with the transformed data
        items,
        pageIndex,
        totalPages,
        pageNumber,
        pageSize,
        totalCount,
      };
      console.log('Notifications Data:', res.data); // log the response data
      console.log('Notifications Result:', res.data.result); // log the notification items
      console.log('Notifications Items NEW:', res.data.result[0]);
      //console.log('Notifications Transformed Data:', transformedData);
      dispatch({
        // dispatch an action to update the notifications list in the Redux store
        type: UPDATE_NOTIFICATIONS_LIST,
        payload: transformedData,
      });
    } catch (err) {
      console.log('error!!!!', err.response); // log the error response
      dispatch(fetchError(err.response)); // dispatch a fetch error action with the error response
    }
  };
};
